import Trix from "trix";

const FG_COLORS = ["rgb(136, 118, 38)", "rgb(185, 94, 6)", "rgb(207, 0, 0)", "rgb(216, 28, 170)", "rgb(144, 19, 254)", "rgb(5, 98, 185)", "rgb(17, 138, 15)", "rgb(148, 82, 22)", "rgb(102, 102, 102)"]

addForegroundColorAttributes(Trix);

addEventListener('trix-initialize', function (event) {
    new RichText(event.target);
});

addEventListener("trix-action-invoke", function (event) {
    const editor = event.target.editor;
    if (event.actionName.startsWith('x-fgColor')) {
        recordUndoEntry(editor, "Changing Color")
        editor.activateAttribute(event.actionName.replace('x-', ''))
        event.target.editorController.toolbarController.hideDialog()
    }

    if (event.actionName.startsWith('x-fgClear')) {
        recordUndoEntry(editor, "Unsetting Colors")
        FG_COLORS.forEach((color, index) => {
            editor.deactivateAttribute(`fgColor${index}`);
        })
        event.target.editorController.toolbarController.hideDialog()
    }
})

function recordUndoEntry(editor, name) {
    const selectionWasFrozen = editor.attributeIsActive("frozen")

    if (selectionWasFrozen) {
        editor.deactivateAttribute("frozen")
    }

    editor.recordUndoEntry(name)

    if (selectionWasFrozen) {
        editor.activateAttribute("frozen")
    }
}

class RichText {
    constructor(element) {
        if (! (element.dataset.colors || false)) return;

        this.element = element;

        this.addToolbarElements()
    }

    addToolbarElements() {
        this.buttonGroupTextTools.insertAdjacentHTML("beforeend", this.colorButtonTemplate)

        this.dialogsElement.insertAdjacentHTML("beforeend", this.dialogColorTemplate)
    }

    get colorButtonTemplate() {
        return '<button type="button" class="trix-button trix-button--icon trix-button--icon-color" data-trix-action="x-color" title="Color" tabindex="-1">Color</button>'
    }

    get dialogColorTemplate() {
        return `
          <div class="trix-dialog trix-dialog--color" data-trix-dialog="x-color" data-trix-dialog-attribute="x-color">
            <div class="trix-dialog__link-fields">
              <input type="text" name="x-color" class="trix-dialog-hidden__input" data-trix-input>
              <div class="trix-button-group">
                    <button type="button" class="trix-button trix-button--dialog" data-trix-action="x-fgColor1"></button>
                    <button type="button" class="trix-button trix-button--dialog" data-trix-action="x-fgColor2"></button>
                    <button type="button" class="trix-button trix-button--dialog" data-trix-action="x-fgColor3"></button>
                    <button type="button" class="trix-button trix-button--dialog" data-trix-action="x-fgColor4"></button>
                    <button type="button" class="trix-button trix-button--dialog" data-trix-action="x-fgColor5"></button>
                    <button type="button" class="trix-button trix-button--dialog" data-trix-action="x-fgColor6"></button>
                    <button type="button" class="trix-button trix-button--dialog" data-trix-action="x-fgColor7"></button>
                    <button type="button" class="trix-button trix-button--dialog" data-trix-action="x-fgColor8"></button>
                    <button type="button" class="trix-button trix-button--dialog" data-trix-action="x-fgColor9"></button>
              </div>
              <div class="trix-button-group">
                    <button type="button" class="trix-button trix-button--dialog no-width no-after" data-trix-action="x-fgClear">Clear all colors</button>
                </div>
            </div>
          </div>
        `
    }

    get buttonGroupTextTools() {
        return this.toolbarElement.querySelector("[data-trix-button-group=text-tools]")
    }

    get toolbarElement() {
        return this.element.toolbarElement
    }

    get dialogsElement() {
        return this.toolbarElement.querySelector("[data-trix-dialogs]")
    }
}

function addForegroundColorAttributes(Trix) {
    Array.from(FG_COLORS).forEach((color, i) => {
        Trix.config.textAttributes[`fgColor${(i + 1)}`] = {
            style: { color },
            inheritable: true,
            parser(element) {
                return element.style.color === color
            }
        }
    })
}

export default Trix;
